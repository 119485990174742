import React from "react";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import "react-perfect-scrollbar/dist/css/styles.css";
import { MuiThemeProvider } from "@material-ui/core/styles";
import Scrollbar from "react-perfect-scrollbar";
import { Theme } from "./theme";
import GlobalCss from "./styles/jss/GlobalCss";
import Inicio from "./core/Inicio";
import AvisoLegal from "./core/AvisoLegal";
import PoliticaPrivacidad from "./core/PoliticaPrivacidad";

function App() {
  return (
    <MuiThemeProvider theme={Theme}>
      <GlobalCss>
        <Scrollbar
          className="h-full-screen scrollable-content"
        //option={{ suppressScrollX: true }}
        >
          <Router basename="/">
            <Switch>
              <Route path="/inicio" component={Inicio} />
              <Route path="/AvisoLegal" component={AvisoLegal} />
              <Route path="/Privacidad" component={PoliticaPrivacidad} />
              <Redirect path="/" exact to="inicio" />
              {/* <Route component={Error} /> */}
            </Switch>
          </Router>
        </Scrollbar>
      </GlobalCss>
    </MuiThemeProvider>
  );
}

export default App;