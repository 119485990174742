import React from "react";
import Card from "@material-ui/core/Card";

//import CardContent from "@material-ui/core/CardContent";
//import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
//import IconButton from "@material-ui/core/IconButton";
//import { Button, Icon } from "@material-ui/core";
//import { makeStyles } from "@material-ui/core/styles";

//const useStyles = makeStyles(({ palette, ...theme }) => ({}));

const ClientelaPotencial = () => {
  //const classes = useStyles();

  const portfoioList = [
    {
      imageUrl: "./assets/images/sq-12.jpg",
      name: "Empresas de Fabricación",
      description: `Empresas de montaje, de mecanizado, de tratamientos térmicos, de 
      reciclado, etc.`,
    },
    {
      imageUrl: "./assets/images/sq-10.jpg",
      name: "Empresas Distribuidoras y Retailers",
      description: `Nuestras aplicaciones gestionan los stocks para que su empresa pueda
      vender con fluided. También disponemos de aplicaciones de comercio electrónico, para que
      su oferta llegue a todo el mundo a través de Internet.`,
    },
    {
      imageUrl: "./assets/images/sq-9.jpg",
      name: "Otras PYMES y Administraciones",
      description: `Nuestro software impulsa, también, a empresas de servicios, 
      asociaciones e instituciones de ambito local, provincial y autonómico.`,
    },
  ];

  return (
    <section className="section" id="portfolio1">
      <div className="container">
        <div className="section__header">
          <h2>A quién van dirigidos nuestros productos</h2>
          <p>
            Asistimos con nuestros productos a una amplia variedad de empresas y de
            organismos rango medio.
          </p>
        </div>
        <Grid container spacing={3}>
          {portfoioList.map((portfolio, index) => (
            <Grid item md={4} sm={4} key={index}>
              <Card className="relative h-full card" key={index}>
                <img
                  className="w-full"
                  src={portfolio.imageUrl}
                  alt="portfolio"
                />
                <div className="flex-column justify-between">
                  <div className="px-4 pt-4">
                    <h5 className="m-0 text-16 font-bold">{portfolio.name}</h5>
                    <p className="mb-4">{portfolio.description}</p>
                    {/*<Divider />*/}
                  </div>
                  {/*
                  <div className="px-4 py-2">
                    <IconButton>
                      <Icon>link</Icon>
                    </IconButton>
                    <IconButton>
                      <Icon>share</Icon>
                    </IconButton>
                  </div>
                  */}
                </div>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    </section>
  );
};

export default ClientelaPotencial;