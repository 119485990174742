import React, { useEffect } from "react";
import { scrollTo } from "../utils";
import IntroSplash from "./secciones/IntroSplash";
import Header from "./secciones/Header";
import Productos from "./secciones/Productos";
import ClientelaPotencial from "./secciones/ClientelaPotencial";
import Banner from "./secciones/Banner";
import ContactarEmail from "./secciones/ContactarEmail";
import Footer from "./secciones/Footer";

const Inicio = () => {
  useEffect(() => {
    scrollTo("root");
  }, []);

  return (
    <div className="landing">
      <Header />
      <IntroSplash />
      <ClientelaPotencial />
      <Productos />
      <Banner />
      <ContactarEmail />
      <Footer />
    </div>
  );
};

export default Inicio;
