import React from "react";
import { useHistory } from 'react-router-dom';
import { Grid, Button, Icon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  footerSection: {
    "& h4:after": {
      content: '" "',
      position: "absolute",
      bottom: -8,
      left: 0,
      height: 2,
      width: 64,
      background: palette.secondary.main,
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  const history = useHistory();
  const handleRoutes = (path) => { history.push(path) }

  return (
    <div className={clsx("bg-light-dark", classes.footerSection)} id="footer1">
      <div className="container">
        <Grid container>
          <Grid item lg={4} md={4} sm={12}>
            <div className="p-8 h-full elevation-z3">
              <h4 className="text-20 mb-6 relative">Privacidad</h4>
              <p className="text-inherit" style={{textAlign:'justify'}}>
                Según lo dispuesto en el Reglamento (UE) 2016/679, de 27 de abril, (RGPD)
                y en la L.O. 3/2018, de 5 de diciembre, de protección de datos y garantía
                de los derechos digitales (LOPDGDD), trataremos su datos tal y como
                reflejamos en la presente Política de Privacidad.
              </p>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleRoutes('/Privacidad')}
              >
                Términos de la privacidad
              </Button>
            </div>
          </Grid>
          <Grid item lg={4} md={4} sm={12}>
            <div className="p-8 h-full elevation-z3">
              <h4 className="text-20 mb-6 relative">Contactar</h4>
              <div className="px-4 my-8 flex items-center mx--4">
                <Icon className="text-secondary">mail</Icon>
                <div className="pl-4">
                  <h5 className="m-0 p-0 text-16">Email</h5>
                  <p className="m-0 p-0 text-inherit">info@geroa.com</p>
                </div>
              </div>
              <div className="px-4 my-8 flex items-center mx--4">
                <Icon className="text-secondary">phone</Icon>
                <div className="pl-4">
                  <h5 className="m-0 p-0 text-16">Teléfono</h5>
                  <p className="m-0 p-0 text-inherit">945 - 245 615</p>
                </div>
              </div>
              <div className="px-4 mt-8 flex items-center mx--4">
                <Icon className="text-secondary">location_on</Icon>
                <div className="pl-4">
                  <h5 className="m-0 p-0 text-16">Dirección</h5>
                  <p className="m-0 p-0 text-inherit">
                    Navarro Villoslada, 4 bajo  01012 Vitoria-Gasteiz
                  </p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item lg={4} md={4} sm={12}>
            <div className="p-8 h-full elevation-z3">
              <h4 className="text-20 mb-6 relative">Legal</h4>
              <p className="text-inherit" style={{textAlign:'justify'}}>
                El responsable del sitio web pone a disposición de los usuarios el presente 
                documento, con el que pretende dar cumplimiento a las obligaciones dispuestas 
                en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información 
                y del Comercio Electrónico (LSSICE).
              </p>
              <Button
                onClick={() => handleRoutes('/AvisoLegal')}
                variant="contained"
                color="secondary">
                Legal
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Footer;