import React from "react";
//import { Grid, Button } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  sectionBG: ({ bg }) => ({
    background: `url(${bg || "./assets/images/home-bg.jpg"
      }) center center/cover no-repeat`,
  }),
}));

const Banner = ({ bg }) => {
  const classes = useStyles({ bg });

  return (
    <div className={clsx("section text-white", classes.sectionBG)} id="cta1">
      <div className="container">
        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="center"
        //justify="flex-start"
        >
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <h2>No pierda el tren de la digitalización </h2>
            <p className="text-inherit">
              El futuro de su empresa está en el comercio electrónico.
            </p>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12} className="text-center">
            {/*
            <Button size="large" color="secondary" variant="contained">
              Solicite una demostración
            </Button>
             */}
          </Grid>
          <Grid item>
            <p className="text-inherit">
              Kit Digital es una iniciativa del Gobierno de España,
              cuyo objetivo es subvencionar la digitalización
              de pymes y autónomos en todo el territorio nacional
              mediante aplicaciones disponibles en el mercado para conseguir un avance significativo
              en el nivel de madurez digital.
            </p>
            <p className="text-inherit">
              Las soluciones de digitalización SOLO serán ofrecidas por
              <b> “Agentes Digitalizadores” </b>
              registrados en el programa Kit Digital.
            </p>
            <a href="https://www.acelerapyme.gob.es/kit-digital" target="_blank" rel="noreferrer">
              <img
                className="w-full"
                style={{ background: 'white' }}
                src={"./assets/images/kitdigital.png"}
                alt="kit-digital-png"
              />
            </a>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Banner;