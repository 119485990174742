import React, { useState } from "react";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Fab, Icon } from "@material-ui/core";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const ContactarEmail = () => {
  const [datos, setDatos] = useState({
    name: "",
    email: "",
    subject: "",
    mensa: "",
  });

  let { name, email, subject, mensa } = datos;



  const handleSubmit = (event) => {
    event.preventDefault()

    enviaEmail({
      name,
      email,
      subject,
      mensa,
    });

    cleanInput({
      name,
      email,
      subject,
      mensa,
    })

    notify()

  };


  const handleChange = (event) => {
    event.persist();
    setDatos({ ...datos, [event.target.name]: event.target.value });

  };

  const cleanInput = (datos) => {
    setDatos({
      name: "",
      email: "",
      subject: "",
      mensa: "",
    });
  }

  const notify = () =>
    toast.success('Solicitud enviada!', {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });




  const enviaEmail = (datosEnvio) => {
    //return fetch('https://geroa.com:17939/api/enviaCorreo/', {
    return fetch('https://geroa.com/server/mails/enviaMail', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }, // credentials: 'include',
      body: JSON.stringify(datosEnvio)
    }).then((response) => {
      return response.json()
    }).catch((err) => console.log('Error = ', err))
  }

  return (
    <div className="section section-contact1 bg-light-gray" id="contactar">
      <div className="container">
        <div className="section__header">
          <h2>Estamos a un Email de distancia</h2>
          <p>
            Si desea recibir información sobre alguno de nuestros productos o servicios, rellene
            el siguiente formulario y envíelo.
          </p>
        </div>

        <ValidatorForm onSubmit={handleSubmit} onError={(errors) => null}>
          <TextValidator
            className="mb-4 w-full"
            label="Nombre"
            onChange={handleChange}
            name="name"
            value={name}
            validators={["required"]}
            errorMessages={["Este campo es obligatorio"]}
          />
          <TextValidator
            className="my-4 w-full"
            label="Email"
            onChange={handleChange}
            name="email"
            multiline
            value={email}
            validators={["required", "isEmail"]}
            errorMessages={["Este campo es obligatorio", "Email no válido"]}
          />
          <TextValidator
            className="my-4 w-full"
            label="Asunto"
            onChange={handleChange}
            name="subject"
            value={subject}
            validators={["required"]}
            errorMessages={["Este campo es obligatorio"]}
          />
          <TextValidator
            className="my-4 mb-8 w-full"
            label="Mensaje"
            onChange={handleChange}
            name="mensa"
            multiline
            value={mensa}
            validators={["required"]}
            errorMessages={["Este campo es obligatorio"]}
          />
          <Fab color="primary" type="submit">
            <Icon>send</Icon>
          </Fab>
          <ToastContainer />
        </ValidatorForm>
      </div>
    </div>
  );
};

export default ContactarEmail;
