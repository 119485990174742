import React, { Fragment } from "react";
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from "@material-ui/core/IconButton";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';



const useStyles = makeStyles(theme => ({
    registro: {
        marginTop: '20px',
        minHeight: '65vh',
        color: 'secondary',
        textAlign: 'justify'
    },
    toTop: {
        zIndex: 2,
        position: 'fixed',
        bottom: '2vh',
        backgroundColor: '#DCDCDC',
        color: 'black',
        "&:hover, &.Mui-focusVisible": {
            transition: '0.3s',
            color: '#397BA6',
            backgroundColor: '#DCDCDC'
        },
        right: '5%',
    },
}));

const PoliticaPrivacidad = () => {
    const classes = useStyles();

/*
    const handleClick = () => {
        window.scrollTo({ top: 0, behavior: 'auto' })
    }
*/    

/*
    useEffect(() => {
        const titulo = document.getElementById('titulo') // id of the parent
        try {
            setTimeout(() => {
                titulo.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            }, 0);
          } catch (error) {
            // just a fallback for older browsers
            titulo.scrollTo(0, 0);
          }
    }, [])
*/

    return (
        <Fragment>
            <CssBaseline />
            <IconButton
                href='\Privacidad'
                //onClick={handleClick}
                className={classes.toTop}>
                <ExpandLessIcon />
            </IconButton>
            <Container id='root' maxWidth="md" className={classes.registro} >
                <Typography id='titulo' align="center" style={{ fontSize: '32px', fontWeight: 700, marginTop: 20, marginBottom: 18 }}>
                    {"Política de Privacidad:"}
                </Typography>
                <p>
                    GEROA, S.L., como Responsable del Tratamiento, le informa que, según lo dispuesto en
                    el Reglamento (UE) 2016/679, de 27 de abril, (RGPD) y en la L.O. 3/2018,
                    de 5 de diciembre, de protección de datos y garantía de los derechos digitales
                    (LOPDGDD), trataremos su datos tal y como reflejamos en la presente Política de
                    Privacidad.
                    <br />
                    En esta Política de Privacidad describimos cómo recogemos sus datos personales y por
                    qué los recogemos, qué hacemos con ellos, con quién los compartimos, cómo los
                    protegemos y sus opciones en cuanto al tratamiento de sus datos personales.
                    <br />
                    Esta Política se aplica al tratamiento de sus datos personales recogidos por la empresa
                    para la prestación de sus servicios. Si acepta las medidas de esta Política, acepta que
                    tratemos sus datos personales como se define en esta Política.
                </p>
                <br />
                <Grid item style={{ marginTop: 1, marginBottom: 1 }}>
                    <Typography style={{ fontSize: '20px', fontWeight: 500, marginBottom: 10 }} >
                        {"1. Contacto"}
                    </Typography>
                    <p>
                        Denominación social: GEROA, S.L.,
                        <br />
                        Nombre comercial: GEROA
                        <br />
                        NIF: B01035674
                        <br />
                        Domicilio: Navarro Villoslada, 4 Bajo
                        <br />
                        e-mail: info@geroa.com
                    </p>
                    <br />
                    <Typography style={{ fontSize: '20px', fontWeight: 500, marginBottom: 10 }} >
                        {"2. Principios Clave"}
                    </Typography>
                    <p>
                        Siempre hemos estado comprometidos con prestar nuestros servicios con el más alto
                        grado de calidad, lo que incluye tratar sus datos con seguridad y transparencia.
                        Nuestros principios son:
                        <br />
                        •	Legalidad: Solo recopilaremos sus Datos personales para fines específicos,
                        explícitos y legítimos.
                        <br />
                        •	Minimización de datos: Limitamos la recogida de datos de carácter personal a
                        lo que es estrictamente relevante y necesario para los fines para los que se han
                        recopilado.
                        <br />
                        •	Limitación de la Finalidad: Solo recogeremos sus datos personales para los
                        fines declarados y solo según sus deseos.
                        <br />
                        •	Precisión: Mantendremos sus datos personales exactos y actualizados.
                        <br />
                        •	Seguridad de los Datos: Aplicamos las medidas técnicas y organizativas
                        adecuadas y proporcionales a los riesgos para garantizar que sus datos no sufran
                        daños, tales como divulgación o acceso no autorizado, la destrucción accidental o
                        ilícita o su pérdida accidental o alteración y cualquier otra forma de tratamiento
                        ilícito.
                        <br />
                        •	Acceso y Rectificación: Disponemos de medios para que acceda o rectifique sus
                        datos cuando lo considere oportuno.
                        <br />
                        •	Conservación: Conservamos sus datos personales de manera legal y apropiada y
                        solo mientras es necesario para los fines para los que se han recopilado.
                        <br />
                        •	Las transferencias internacionales: cuando se dé el caso de que sus datos vayan
                        a ser transferidos fuera de la UE/EEE se protegerán adecuadamente.
                        <br />
                        •	Terceros: El acceso y transferencia de datos personales a terceros se llevan a
                        cabo de acuerdo con las leyes y reglamentos aplicables y con las garantías
                        contractuales adecuadas.
                        <br />
                        •	Marketing Directo y cookies: Cumplimos con la legislación aplicable en materia
                        de publicidad y cookies.
                    </p>
                    <br />
                    <Typography style={{ fontSize: '20px', fontWeight: 500, marginBottom: 10 }} >
                        {"3. Recogida y tratamiento de datos personales"}
                    </Typography>
                    <p>
                        Las tipos de datos que se pueden solicitar y tratar son:
                        <br />
                        •	Datos de carácter identificativo.
                        <br />
                        También recogemos de forma automática datos sobre su visita a nuestro sitio web
                        según se describe en la política de cookies.
                        <br />
                        Siempre que solicitemos sus Datos personales, le informaremos con claridad de qué
                        datos personales recogemos y con qué fin. En general, recogemos y tratamos sus
                        datos personales con el propósito de:
                        <br />
                        •	Proporcionar información, servicios, productos, información relevante y
                        novedades en el sector.
                        <br />
                        •	Envío de comunicaciones.
                    </p>
                    <br />
                    <Typography style={{ fontSize: '20px', fontWeight: 500, marginBottom: 10 }} >
                        {"4. Legitimidad"}
                    </Typography>
                    <p>
                        De acuerdo con la normativa de protección de datos aplicable, sus datos
                        personales podrán tratarse siempre que:
                        <br />
                        •	Nos ha dado su consentimiento a los efectos del tratamiento.
                        Por supuesto podrá retirar su consentimiento en cualquier momento.
                        <br />
                        •	Por requerimiento legal.
                        <br />
                        •	Por existir un interés legítimo que no se vea menoscabado por sus derechos
                        de privacidad, como por ejemplo el envío de información comercial bien por
                        suscripción a nuestra newsletter o por su condición de cliente.
                        <br />
                        •	Por ser necesaria para la prestación de alguno de nuestros servicios mediante
                        relación contractual entre usted y nosotros.
                    </p>
                    <br />
                    <Typography style={{ fontSize: '20px', fontWeight: 500, marginBottom: 10 }} >
                        {"5. Comunicación de datos personales"}
                    </Typography>
                    <p>
                        Los datos pueden ser comunicados a empresas relacionadas con GEROA, S.L. para la
                        prestación de los diversos servicios en calidad de Encargados del Tratamiento.
                        La empresa no realizará ninguna cesión, salvo por obligación legal.
                    </p>
                    <br />
                    <Typography style={{ fontSize: '20px', fontWeight: 500, marginBottom: 10 }} >
                        {"6. Sus derechos"}
                    </Typography>
                    <p>
                        En relación con la recogida y tratamiento de sus datos personales, puede ponerse
                        en contacto con nosotros en cualquier momento para:
                        <br />
                        •	Acceder a sus datos personales y a cualquier otra información indicada en el
                        Artículo 15.1 del RGPD.
                        <br />
                        •	Rectificar sus datos personales que sean inexactos o estén incompletos de
                        acuerdo con el Artículo 16 del RGPD.
                        <br />
                        •	Suprimir sus datos personales de acuerdo con el Artículo 17 del RGPD.
                        <br />
                        •	Limitar el tratamiento de sus datos personales de acuerdo con el Artículo 18
                        del RGPD.
                        <br />
                        •	Solicitar la portabilidad de sus datos de acuerdo con el Artículo 20 del RGPD.
                        <br />
                        •	Oponerse al tratamiento de sus datos personales de acuerdo con el artículo
                        21 del RGPD.
                        <br />
                        Si ha otorgado su consentimiento para alguna finalidad concreta, tiene derecho a
                        retirar el consentimiento otorgado en cualquier momento, sin que ello afecte a la
                        licitud del tratamiento basado en el consentimiento previo a su retirada.
                        <br />
                        Puede ejercer estos derechos enviando comunicación, motivada y acreditada, a
                        info@geroa.com
                        <br />
                        También tiene derecho a presentar una reclamación ante la Autoridad de control
                        competente (www.aepd.es) si considera que el tratamiento no se ajusta a la
                        normativa vigente.
                    </p>
                    <br />
                    <Typography style={{ fontSize: '20px', fontWeight: 500, marginBottom: 10 }} >
                        {"7. Información Legal"}
                    </Typography>
                    <p>
                        Los requisitos de esta Política complementan, y no reemplazan, cualquier otro
                        requisito existente bajo la ley de protección de datos aplicable, que será la
                        que prevalezca en cualquier caso.
                        <br />
                        Esta Política está sujeta a revisiones periódicas y la empresa puede modificarla
                        en cualquier momento. Cuando esto ocurra, le avisaremos de cualquier cambio y le
                        pediremos que vuelva a leer la versión más reciente de nuestra Política y que
                        confirme su aceptación.
                    </p>
                    <br />
                </Grid>
            </Container>
        </Fragment>
    );
}

export default PoliticaPrivacidad;