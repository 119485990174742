import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Icon } from "@material-ui/core";
//import { makeStyles } from "@material-ui/core/styles";

import Carousel from "../componentes/Carousel";
/*
const useStyles = makeStyles(({ palette, ...theme }) => ({
  card: {
    borderTop: "2px solid black",
    "& .icon": {
      fontSize: 64,
    },

    "&:hover": {
      borderTop: "2px solid rgba(var(--primary), 1)",
      "& .icon": {
        color: "rgba(var(--primary),1)",
      },
    },
  },
}));
*/

const Productos = () => {
  //const classes = useStyles();

  const productsList = [
    {
      icon: "dashboard",
      title: "Aplicaciones de Gestión",
      text:
        "Aplicaciones de escritorio para la gestión administrativa de su empresa. Controle de forma sencilla e intuitiva la totalidad de los procesos administrativos de su empresa. Si además su organización tributa en las haciendas forales, la gestión estará adaptada a la normativa tributaria TicketBAI y Batuz de las haciendas vascas.",
    },
    {
      icon: "perm_data_setting",
      title: "Comercio Electrónico",
      text:
        "Página web segura en internet para el comercio electrónico de sus productos. Conexión directa con su aplicación de gestión administrativa de su empresa que le evitará definir o modificar manualmente sus productos vendidos en internet, sus precios, sus características, etc. Además le captará de forma automática los pedidos que sus clientes en internet de sus productos.",
    },
    {
      icon: "storage",
      title: "Copias de Seguridad por Internet",
      text:
        "La seguridad de sus datos es un aspecto de la mayor relevancia para su empresa. Cualquier destrucción de datos que sufra, ya sea como consecuencia de un uso inadecuado, un virus, o un accidente, tendrá una solución rápida y fiable a través de nuestro sistema de copias de seguridad remotas.",
    },
    {
      icon: "stay_primary_portrait",
      title: "Aplicaciones a Medida",
      text:
        "Las aplicaciones informáticas existentes en la actualidad, puede que no satisfagan sus necesidades de gestión administrativa o de producción de su organización. Si necesita una aplicación informática para satisfacer estas necesidades, podemos realizarla.",
    },
    {
      icon: "apps",
      title: "Kit digital y Agente digitalizador",
      text:
        "Te ayudamos a digitalizar tu empresa:",
      textos: [
        "Sitio web y presencia básica en internet.",
        "Comercio electrónico.",
        "Gestión de clientes.",
        "Gestión de procesos.",
        "Factura electrónica."],
    },
  ];

  return (
    <section className="section bg-light-gray" id="productos">
      <div className="container">
        <div className="section__header">
          <h2>Nuestros productos y servicios</h2>
          <p>
            Estos son nuestros productos y servicios.
          </p>
        </div>
        <Carousel carouselId="productos-1">
          {productsList.map((producto, index) => (
            <Card className="px-6 card w-full h-full" key={index}>
              <CardContent className="flex-column justify-between min-h-full">
                <div className="flex-grow">
                  <div className="text-center mb-4">
                    <Icon className="icon">{producto.icon}</Icon>
                  </div>
                  <h3 className="font-light text-24">{producto.title}</h3>
                  <p>{producto.text}</p>
                  {producto.textos !== undefined &&
                    producto.textos.map((texto, i) => (
                      <div key={"txt"+i}>
                        {texto}
                        <br />
                      </div>
                    ))}
                </div>
              </CardContent>
            </Card>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default Productos;